.WalletMainSlide__MoneyActionsBtnsContainer {
    display: flex;
    position: absolute;
    top: 51.5vw;
    left: 0;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}
.WalletMainSlide__DepositBtn {
    /* position: absolute; */
    /* top: 50.5vw; */
    /* left: 5%; */
    width: 35vw;
    text-align: center;
    font-size: 4vw;
    background-color: #ffbf12;
    border-radius: 8px;
    line-height: 8vw;
    color: black;
    font-weight: normal;
    box-shadow: 0 0 3px 1px #90909054;
    margin: 0;
}
.WalletMainSlide__WithdrawBtn {
    width: 35vw;
    height: 8vw;
    line-height: 7.5vw;
    text-align: center;
    font-size: 4vw;
    border: 2px solid #ffbf12;
    border-radius: 8px;
    color: black;
    box-shadow: inset 0px 0px 6px 0px #90909054, 0 0 6px #90909054;
    margin: 0;
    font-weight: normal;
}