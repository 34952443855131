.SorryMC__Emoji {
  width: 23vw;
  margin-left: 6vw;
}
.SorryMC__ItsTxt {
  font-size: 10vw;
  line-height: 9vw;
  margin: 0 0 3vw;
}
.SorryMC__AtTxtSorry {
  font-size: 4.2vw;
  line-height: 6vw;
  margin: 0 0 4vh;
}
.SorryMC__EmailInput {
  width: 100%;
  padding: 0 4vw;
  font-size: 4vw;
  line-height: 11vw;
  color: black;
  border-radius: 25px;
  border: none;
}
.SorryMC__indicate-container {
  height: 12vw;
  padding-bottom: 6vh;
}
.SorryMC__JoinWaitlistBtn {
  width: 48%;
  /* padding: 0 3vw; */
  font-size: 4.5vw;
  line-height: 8vw;
  background-color: #ffbf12;
  color: white;
  border-radius: 25px;
  border: none;
}
.SorryMC__SuccessMsg {
  position: fixed;
  bottom: 8vw;
  padding: 5vw;
  background: #363636;
  border-radius: 4.5vw;
  margin: 0;
  width: 84vw;
  box-shadow: 0 0vw 2.5vw 0 #fff;
  z-index: 60;
}
.SorryMC__SuccessMsg p {
  font-size: 4.25vw;
  text-align: center;
  line-height: 6vw;
  margin: 0 auto 7vw;
  color: white;
}
.SorryMC__SuccessMsgDoneBtn {
  padding: 0px 4vw;
  width: fit-content;
  margin: 0 auto;
  display: block;
}
