.NetWorth {
  width: 90%;
  text-align: left;
  background-color: #e8e8e8;
  box-shadow: 0 0 2vw #ffbf12;
  border-radius: 5vw;
  margin: 20vw auto 0;
  padding: 5vw 3vw;
}
.NetWorth p {
  text-align: left;
  font-size: 10vw;
  color: black;
  margin: 0 0 4vw;
  line-height: 10vw;
}
.NetWorth span {
  display: block;
  font-size: 4.3vw;
  line-height: 5vw;
  color: #353535 !important;
}
.NetWorth__BtnsContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}
.NetWorth button {
  width: 35vw;
  text-align: center;
  font-size: 4vw;
  border-radius: 3vw;
  color: black;
  font-weight: normal;
  box-shadow: 0 0 3px 1px #90909054;
  margin: 0;
}
.NetWorth__DepositBtn {
  background-color: #ffbf12;
  border: 0;
  line-height: 8vw;

}
.NetWorth__WithdrawBtn {
  line-height: 7.5vw;
  border: 0.5vw solid #ffbf12;
  box-shadow: inset 0px 0px 6px 0px #90909054, 0 0 6px #90909054;
  background: none;
}
