#RewardsDiv{
    position: absolute;
    top: 15vw;
    left: 0vw;
    width: 100%;
    height: 120vw;
}


#UnvPicR {
    position: absolute;
    top: 57vw;
    left: 37vw;
    width: 28vw;
}
#CurrentlyTxtR {
    position: absolute;
    top: 110vw;
    left: 0;
    width: 100vw;
    text-align: center;
    font-size: 9vw;
    line-height: 11vw;
    margin: 0;
}

#SpreadTxt{
    position: absolute;
    top: 11vw;
    left: 8%;
    font-size: 11vw;
    line-height: 12vw;
}
#GetTxt{
    position: absolute;
    top: 40vw;
    left: 8%;
    font-size: 7vw;
    line-height: 9vw;
    color: #FFBF12;
}
#WinkingSmiley{
    position: absolute;
    top: 69vw;
    left: 69%;
    width: 16%;
}
#Hide{
    display: none;
}
#InviteTxt{
    position: absolute;
    top: 82vw;
    left: 5.5%;
    width: 45.5%;
    height: 25vw;
    font-size: 4.5vw;
    line-height: 26vw;
    text-align: center;
    margin: 0;
    padding: 0;
}
#ReferralCodeTxt{
    position: absolute;
    top:82vw;
    left: 48.5%;
    width: 45.5%;
    height: 25vw;
    font-size: 4.5vw;
    line-height: 26vw;
    text-align: center;
    margin: 0;
    padding: 0;
}
#InviteSubLine{
    position: absolute;
    top: 102vw;
    left: 8%;
}
#ReferralCodeSubLine{
    position: absolute;
    top: 102vw;
    left: 51%;
}
#InviteDiv{
    position: absolute;
    top:103vw;
    left:0%;
    width: 100%;
    height: 56vw;
}
#InviteCode{
    position: absolute;
    top:1vw;
    left: 8%;
    font-size: 9vw;
}
#Copy{
    position: absolute;
    top: 12vw;
    left: 49%;
    width: 7%;
}
#YourTxt{
    position: absolute;
    top: 15vw;
    left: 8%;
    font-size: 4.5vw;
}
#ShareBtn{
    position: absolute;
    top: 32vw;
    left: 8%;
    width: 76%;
    padding: 0 4vw;
    text-align: center;
    font-size: 5vw;
    background-color: #FFBF12;
    border-radius: 10px;
}
#ReferralCodeDiv{
    position: absolute;
    top: 103vw;
    left: 0;
    width: 100%;
    height: 56vw;
}
#InputReferralCode{
    position: absolute;
    top: 12vw;
    left: 10%;
    width: 79%;
    font-size: 3vw;
    line-height: 10vw;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
}
#ApplyCodeBtn{
    position: absolute;
    top: 31vw;
    left: 32%;
    /* width: 38%; */
    padding: 0 6vw;
    font-size: 4vw;
    text-align: center;
    border: 3px solid #FFBF12;
    border-radius: 10px;
}