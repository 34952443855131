#CToPPTSDiv{
    position: absolute;
    left: 0;
    top:15vw;
    width: 100%;
}
#BackPageCToPPTS {
    position: absolute;
    top: 2vw;
    left: 0%;
    width: 13vw;
    z-index: 35;
}

#PSTxtCToPPTS {
    position: absolute;
    top: 16vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 10.5vw;
    line-height: 10vw;
    margin: 0;
}

#ConfirmTxtCToPPTS {
    position: absolute;
    top: 56.5vw;
    left: 8vw;
    font-size: 4.3vw;
    line-height: 7vw;
    color: #b8b8b8;
    margin: 0;
}
#HandleSignPPTS {
    position: absolute;
    top: 60.5vw;
    left: 10vw;
    font-size: 5vw;
    line-height: 7vw;
    color: black;
    font-weight: normal;
    z-index: 5;
}
#HandleInputCToPPTS {
    position: absolute;
    top: 65vw;
    left: 8vw;
    width: 84%;
    border: none;
    border-radius: 7px;
    font-size: 4.3vw;
    line-height: 9vw;
    padding: 0 2.5vw 0 8vw;
    margin: 0;
}
#HandleInputCToPPTS::placeholder{
    color: black;
    font-size: 4.3vw;
}

#TheProductTxtCToPPTS {
    position: absolute;
    top: 82vw;
    left: 8vw;
    font-size: 4.3vw;
    line-height: 6vw;
    color: #b8b8b8;
    margin: 0;
}
#LineCToPPTS {
    position: absolute;
    top: 134vw;
    left: 31.5vw;
}
#DoneCToPPTS {
    position: absolute;
    top: 143vw;
    left: 43.5vw;
    width: 13vw;
}
#successMessageCTPPTS {
    position: fixed;
    bottom: 0;
    background-color: black;
    font-size: 5vw;
    line-height: 7vw;
    width: 100%;
    text-align: center;
    padding: 10vw 0;
    margin: 0;
}