.StockHolding {
  position: relative;
  width: 100%;
}
.StockHolding__StockContainer {
  position: relative;
  margin: 0 5vw;
  width: 90%;
  background-color: #2c2c2c;
  border-radius: 26px;
  z-index: 4;
}
.StockHolding__ListBtn {
  position: absolute;
  top: 30vw;
  right: 5%;
  font-size: 3.75vw;
  line-height: 8vw;
  margin: 0;
  padding: 0 4vw;
  text-align: center;
  border-radius: 10px;
  background-color: #ffbf12;
  z-index: 50;
}
.StockHolding__UnitsAmount {
  position: relative;
  width: 100%;
  color: #ffbf12;
  font-size: 6vw;
  line-height: 12vw;
  margin: 3vw 0 0;
  text-align: center;
}
.StockHolding__UnitsAmount span{
  color: white;
  font-size: 4.5vw;
}
.StockHolding__ArrowDown {
  display: block;
  margin: 0.5vw auto 1.2vw;
  width: 6vw;
}
.StockHolding__OnSaleContainer {
  position: relative;
  width: 90vw;
  margin: 0 5vw;
}
.ForSaleStS {
  width: 90%;
  height: 26vw;
  background-color: #363636;
  border-radius: 20px;
  border-bottom: 0.1px solid #707070;
  /* visibility: hidden; */
}
.ForSaleTxtStS {
  position: absolute;
  bottom: 3.5vw;
  left: 20%;
  width: 100%;
  font-size: 6vw;
  text-align: left;
  opacity: 1;
  margin: 0;
  line-height: 7vw;
}
.ForSaleTxtStS i {
  position: absolute;
  top: 0vw;
  left: -10vw;
  font-size: 9vw;
  font-style: normal;
  text-align: left;
}
.ForSaleTxtStS spam {
  color: #ffbf12;
}

