.StockCreativeCard{
    position: relative;
    width: 100%;
    height: 34vw;
    background-color: #1F1F1F;
    box-shadow: 0px 13px 9px -6px #121212;
    z-index: 12;
}
#SMIPicCreative {
    position: absolute;
    left: 4vw;
    top: 5.5vw;
    width: 23vw;
    height: 23vw;
    border: 4.5px solid #24BEFF;
    border-radius: 50%;
    background-size: 21vw 21vw;
}
.blue{
    border: 4.5px solid #24BEFF;
}
.purple{
    border: 4.5px solid #902366;
}
.yellow{
    border: 4.5px solid #FFBF12;
}
.SMINameCreative{
    position: absolute;
    left: 38%;
    top: 5.5vw;
    line-height: 6vw;
    font-size: 5vw;
    margin: 0;
}
.SMIRatingSCC {
    position: absolute;
    top: 14.5vw;
    left: 23.3vw;
    background-color: #24beff;
    border-radius: 54px;
    font-size: 3vw;
    line-height: 6.5vw;
    padding: 0 2.3vw;
    margin: 0;
    box-shadow: 0px 8px 20px 2px #121212;
    z-index: 4;
}
.StockPriceCreative{
    position: absolute;
    left: 38%;
    top: 13vw;
    margin: 0;
    font-size: 5vw;
    line-height: 5vw;
}
.ArrowCreative{
    position: absolute;
    display: inline;
    left: 60%;
    top: 14vw;
    width: 3.5%;
}
.SMISNicheCreative{
    position: absolute;
    left: 38%;
    top: 19.65vw;
    font-size: 3vw;
    line-height: 3vw;
    font-weight: normal;
    margin: 0;
}
.SMILocationCreative{
    position: absolute;
    left: 38%;
    top: 24.7vw;
    font-size: 3vw;
    margin: 0;
    text-align: left;
    line-height: 3vw;
}