#BAADiv{
    position: absolute;
    top: 15vw;
    left: 0;
    width: 100%;
    /* height: 290vw; */
}
#BackPageBAA{
    position: absolute;
    top: 7vw;
    left: 0%;
    width: 13vw;
}
#BAABckgImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}
#BAATxt{
    position: absolute;
    top: 70vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 7.5vw;
    line-height: 7vw;
    margin: 0;
    z-index: 2;
}
#ShadowDivBAA {
    position: absolute;
    top: 103vw;
    left: 0;
    width: 100%;
    height: 30vw;
    background-color: black;
    box-shadow: 0 -12vw 21vw 8vw black;
}
#AnAgencyTxtBAA {
    position: absolute;
    top: 96vw;
    left: 8%;
    font-weight: lighter;
    font-size: 4vw;
    line-height: 5vw;
    margin: 0;
    z-index: 2;
}
#AnAgencyTxtBAA span {
    font-size: 7vw;
    color: #ffbf12;
    padding-right: 2vw;
}
#AnAgencyTxtBAA i {
    position: relative;
    left: 18.7vw;
    font-style: normal;
}

#FullnameInputBAA {
    position: absolute;
    top: 184vw;
    left: 8%;
    width: 84vw;
    height: 9vw;
    font-size: 4vw;
    border: none;
    border-radius: 9px;
    padding: 0 4vw;
    color: #363636;
}
#EmailInputBAA{
    position: absolute;
    top: 195vw;
    left: 8%;
    width: 84vw;
    height: 9vw;
    font-size: 4vw;
    border: none;
    border-radius: 9px;
    padding: 0 4vw;
    color: #363636;
}
#PhoneNumberInputBAA{
    position: absolute;
    top: 206vw;
    left: 8%;
    width: 84vw;
    height: 9vw;
    font-size: 4vw;
    border: none;
    border-radius: 9px;
    padding: 0 4vw;
    color: #363636;
}
#FullnameInputBAA::placeholder,#EmailInputBAA::placeholder,#PhoneNumberInputBAA::placeholder{
    font-size: 3.5vw;
    color: #363636;
}

#SpeakSoonTxtBAA {
    position: absolute;
    top: 233vw;
    left: 0%;
    width: 100%;
    text-align: center;
    line-height: 9vw;
    font-size: 8vw;
    margin: 0;
}

#RegisterBtnBAA {
    position: absolute;
    top: 253vw;
    left: 24%;
    width: 53.5vw;
    line-height: 9vw;
    font-size: 4.5vw;
    margin: 0;
    background-color: #ffbf12;
    border-radius: 11px;
    border: none;
}
#LoaderPosSizeBAA {
    position: absolute;
    top: 268vw;
    left: 48%;
    width: 4vw;
    height: 4vw;
}

#SuccesedMessageBAA{
    position: fixed;
    bottom: 5vw;
    left: 5vw;
    width: 90vw;
    height: 56vw;
    background-color: #363636;
    border-radius: 25px;
    z-index: 3;
}
#SuccesedMessageBAA p{
    position: absolute;
    top: 7vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 4.5vw;
    line-height: 6.5vw;
    margin: 0;
}
#SuccesedMessageBAA span{
    color: #ffbf12;
    font-size: 5vw;
}
#SuccesedMessageBAA button {
    position: absolute;
    top: 40vw;
    left: 25vw;
    width: 40vw;
    line-height: 9vw;
    font-size: 4.5vw;
    background-color: #ffbf12;
    border: none;
    border-radius: 25px;
}
