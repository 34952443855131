/* #MenuDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120vw;
}
#BackPageTrade {
    position: absolute;
    top: 21vw;
    left: 0%;
    width: 13vw;
    z-index: 35;
} */
.Menu__Avatar-image {
  width: 26vw;
  height: 26vw;
  margin-top: 23vw;
}
.Menu__options {
  position: relative;
}
.Menu__JoinTExchangeBtn {
    width: fit-content;
    padding: 0 5vw;
    background-color: #ffffff;
    line-height: 9vw;
    border-radius: 10px;
    font-size: 4vw;
    text-align: center;
    color: #ffbf12;
    margin: 0 0 4vw 0;
}
.Menu__BecomeAnAgentBtn {
  width: fit-content;
  padding: 0px 5vw;
  background-color: #ffbf12;
  border-radius: 10px;
  line-height: 9vw;
  font-size: 4vw;
  text-align: center;
  margin: 0;
}
.Menu__list {
    position: relative;
    height: 48vw;
    width: 100%;
    margin: 6vw 0 0 0;
}
#QuestionCircle {
  position: absolute;
  top: 0vw;
  left: 0%;
  width: 7vw;
}
#FAQSTxt {
  position: absolute;
  top: 0.5vw;
  left: 10vw;
  font-size: 4.5vw;
  margin: 0;
  line-height: 6vw;
}
#LockCircle {
  position: absolute;
  top: 0vw;
  left: 0.25vw;
  width: 6vw;
}
#SecurityTxt {
  position: absolute;
  top: 0.5vw;
  left: 10vw;
  font-size: 4.5vw;
  margin: 0;
  line-height: 6vw;
}
#HelpCircle {
  position: absolute;
  top: 0vw;
  left: 0%;
  width: 7vw;
}
#HelpTxt {
  position: absolute;
  top: 0.5vw;
  left: 10vw;
  font-size: 4.5vw;
  margin: 0;
  line-height: 6vw;
}
#LegalCircle {
  position: absolute;
  top: 0vw;
  left: 0;
  width: 7vw;
}
#LegalTxt {
  position: absolute;
  top: 0.5vw;
  left: 10vw;
  font-size: 4.5vw;
  margin: 0;
  line-height: 6vw;
}
.Menu__log-out-container{
    width: 100%;
    height: 19vw;
}
.Menu__LogOutBtn {
    width: 40vw;
    padding: 0 11vw;
    border: 2px solid #902366;
    border-radius: 10px;
    font-size: 4vw;
    line-height: 9vw;
    text-align: center;
    margin: 0 auto 9vw auto;
}
#UndisplayLOM {
  display: none;
}
