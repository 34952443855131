.SaleLine {
  display: flex;
  width: 100%;
  justify-content: space-between;

  background-color: #2e2e2e;
  border-bottom: 0.1px solid #707070;
}
.SaleLine__Data {
  display: inline;
  margin: auto 0;
  font-size: 4vw;
}
.SaleLine i {
  font-size: 5vw;
  font-style: normal;
  text-align: left;
  margin: auto  4vw;
}
.SaleLine span {
  color: #ffbf12;
}
.SaleLine__BtnsContainer {
  display: flex;
  height: 6vw;
  margin: auto 3vw;
}
.SaleLine__RemoveBtn {
  width: fit-content;
  padding: 0 1vw;
  font-size: 2.4vw;
  line-height: 6vw;
  text-align: center;
  font-weight: normal;
  background-color: #902366;
  border-radius: 7px;
  margin: 0 0 0 1vw;
}
.SaleLine__UpdateBtn {
  width: fit-content;
  padding: 0 1vw;
  font-size: 2.4vw;
  line-height: 6vw;
  text-align: center;
  font-weight: normal;
  background-color: #ffbf12;
  border-radius: 7px;
  margin: 0 1vw 0 0;
}
