.Help__Title{
    position: absolute;
    top: 16vw;
    left: 15vw;
    font-size: 4.5vw;
}
.Help__BugReportPic{
    position: absolute;
    top: 59vw;
    left: 12vw;
    width: 7vw;
}
.Help__ReportTxt{
    position: absolute;
    top: 53.5vw;
    left: 22vw;
    font-size: 4.5vw;
}
.Help__PhonePic{
    position: absolute;
    top: 78vw;
    left: 12vw;
    width: 7vw;
}
.Help__ContactUsTxt{
    position: absolute;
    top: 71.5vw;
    left: 22vw;
    font-size: 4.5vw;
}
.Help__Mail{
    position: absolute;
    top: 88vw;
    left: 10vw;
    width: 80vw;
    padding: 0 4vw;
    font-size: 4.5vw;
    background-color: #363636;
    border-radius: 10vw;
    color: #707070;
}
.Help__PhoneNumber{
    position: absolute;
    top: 102vw;
    left: 10vw;
    width: 80vw;
    padding: 0 4vw;
    font-size: 4.5vw;
    background-color: #363636;
    border-radius: 10vw;
    color: #707070;
}




