#UpdateInstaSMDiv {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin: 2vw 8vw 0 ;
}
#DefaultDiv {
    position: relative;
    top: 0;
    width: 84vw;
    height: 51vw;
    background-color: #363636;
    border-radius: 10px;
    z-index: 10;
}
#DefaultTxt{
    position: absolute;
    top: 0vw;
    padding: 0;
    margin: 0;
    left: 5%;
    line-height: 10vw;
    font-size: 3.5vw;
}
#InstagramBigIcon{
    position: absolute;
    top: 9vw;
    left: 1%;
    width: 42vw;
}
#ISMTxt{
    position: absolute;
    top: 11vw;
    left: 52%;
    padding: 0;
    margin: 0;
    font-size: 4.8vw;
    line-height: 6vw;
}
#OneTxt{
    position: absolute;
    top: 30vw;
    left: 52%;
    margin: 0;
    font-size: 16vw;
    color: #FFBF12;
}
#PSTxt{
    position: absolute;
    top: 32vw;
    left: 65%;
    margin: 0;
    font-size: 5vw;
    line-height: 5vw;
    color: #FFBF12;
}
#IndicateListDiv {
    position: relative;
    top: -5vw;
    left: 0;
    width: 84%;
    /* min-height: 43vw; */
    background-color: #434343;
    border-radius: 10px;
    padding: 0 0 11vw 0;
}
#IndicateListDiv>ul {
    position: relative;
    top: 6vw;
    left: 0%;
    width: 100%;
    padding: 2vw 0 0 0;
    margin: 0;
    background-color: #434343;
    border-radius: 10px;
}
#IndicateExpTxt{
    position: relative;
    top: 8vw;
    left: 5%;
    margin: 0;
    font-size: 3.6vw;
    line-height: 4.8vw;
    font-weight: normal;
}
#IndicateListLine{
    position: relative;
    top: 4vw;
    left: 5%;
}
#BlurComingSoonSMIP {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 28vw;
    background-color: rgb(240, 248, 255, 0.7);
    z-index: 8;
}
#CSUDSM {
    position: absolute;
    bottom: 12vw;
    left: 28vw;
    font-size: 6vw;
    line-height: 5vw;
    margin: 0;
    color: black;
    z-index: 9;
}
#WinkingSmileyUDSM {
    position: absolute;
    bottom: 8vw;
    left: 12vw;
    width: 15vw;
    z-index: 9;
}
#IndicateListDiv>ul{
    position: relative;
    top: 6vw;
    left: 0%;
    width: 100%;
    padding: 0;
    margin: 0;
}
#IndicateListDiv li{
    position: relative;
    left: 5%;
    width: 90%;
    padding: 4vw 0;
    list-style: none;
}
#IndicateListDiv li p{
    position: absolute;
    top: 0vw;
    left: 0%;
    padding: 0;
    margin: 0;
    line-height: 5vw;
    font-size: 4vw;
    font-weight: normal;
}
#IndicateListDiv li spam{
    position: absolute;
    top: 0vw;
    right: 0;
    width: 14.5%;
    height: 4.95vw;
}
#ExstraHeight{
    position: relative;
    top: 8vw;
    left: 0;
    width: 100%;
    height: 8vw;
}