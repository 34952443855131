.Security__Title{
    position: absolute;
    top: 16vw;
    left: 16vw;
    font-size: 4.5vw;
}
.Security__KeyIcon{
    position: absolute;
    top: 0vw;
    left: 11vw;
    width: 9vw;
}
.Security__PasswordTxt{
    position: absolute;
    top: 0vw;
    left: 22vw;
    font-size: 4.5vw;
    margin: 0;
}
.Security__YourAccountTxt{
    margin: 2vw 10vw;
    width: 80vw;
    font-size: 4vw;
    line-height: 5vw;
    font-weight: normal;
}
.Security__CurrentPInput,.Security__NewPInput,.Security__ConfirmPInput{
    width: 80vw;
    font-size: 4.5vw;
    /* padding: 2.5vw 5.5vw; */
    /* border-radius: 17px; */
    background: #363636;
    border: none;
    color: white;
}
.Security__CurrentPInput::placeholder,.Security__NewPInput::placeholder,.Security__ConfirmPInput::placeholder{
    color: #b7b7b7;
    font-weight: normal;
    font-size: 4.5vw;
}
.Security__UpdatePBtn{
   margin: 8vh auto 0;
    font-size: 4.5vw;
}