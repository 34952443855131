#CPPTTPSSDiv{
    position: absolute;
    left: 0;
    top:15vw;
    width: 100%;
}
#BackPageCPPTTPSS {
    position: absolute;
    top: 2vw;
    left: 0%;
    width: 13vw;
    z-index: 35;
}

#PSTxtCPPTTPSS {
    position: absolute;
    top: 28vw;
    left: 8vw;
    font-size: 8.5vw;
    line-height: 10vw;
    margin: 0;
}

#ConfirmTxtCPPTTPSS {
    position: absolute;
    top: 56.5vw;
    left: 8vw;
    font-size: 4.3vw;
    line-height: 7vw;
    color: #b8b8b8;
    margin: 0;
}

#HandleInputCPPTTPSS {
    position: absolute;
    top: 65vw;
    left: 8vw;
    width: 79%;
    border: none;
    border-radius: 7px;
    font-size: 4.3vw;
    line-height: 9vw;
    padding: 0 2.5vw;
    margin: 0;
}
#HandleInputCPPTTPSS::placeholder{
    color: black;
    font-size: 4.3vw;
}

#TheProductTxtCPPTTPSS {
    position: absolute;
    top: 73vw;
    left: 8vw;
    font-size: 4.3vw;
    line-height: 6vw;
    color: #b8b8b8;
    margin: 0;
}
#LineCPPTTPSS {
    position: absolute;
    top: 122vw;
    left: 32.5vw;
}
#DoneCPPTTPSS {
    position: absolute;
    top: 136vw;
    left: 43.5vw;
    width: 13vw;
}
#successMessageCTPPTS {
    position: fixed;
    bottom: 0;
    background-color: black;
    font-size: 5vw;
    line-height: 7vw;
    width: 100%;
    text-align: center;
    padding: 10vw 0;
    margin: 0;
}