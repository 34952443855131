.MyBrand {
  top: 15vw;
}
.MyBrand * {
  margin: 0 auto;
  text-align: center;
}
.MyBrand__Headline {
  font-size: 10.5vw;
  text-align: center;
  margin: 4vw auto 8vw;
}

.MyBrand__Stats__Date {
  margin: 5vw auto 1vw;
  color: #909090;
  font-size: 4.5vw;
}
.MyBrand__Stats__BrandWorthValue{
  margin: 2vw auto 8vw;
  font-size: 200%;
}
.MyBrand__Stats__IpoProgressSliderContainer {
  width: 90vw;
  display: flex;
  align-items:normal;
}
.MyBrand__Stats__IpoProgressSlider{
    width: 70vw;
    border: 0.5vw solid white;
    border-radius: 2.5vw;  
    margin: 0;
}
.MyBrand h2{
    color: white;
    font-size: 4.75vw;
}
.MyBrand__Stats__BrandProgMetrics{
  margin: 8vw 0;
}
.MyBrand__Stats__Block{
    background: #2e2e2e;
    border-radius: 5vw;
    padding: 3vw;
    width: 76vw;
    margin: 1.5vw auto 5vw;
    font-weight: lighter;
    font-size: 4vw;
    line-height: 6vw;
}
.MyBrand__Stats__Block h2{
  font-size: 5vw;
  margin: 0;
  line-height: 8vw;
}
.MyBrand__Stats__Block h3{
  font-weight: lighter;
  font-size: 5vw;
}
.MyBrand__FundamentalsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin: 0 auto;
  width: 90vw;
}
.MyBrand__FundamentalsContainer div{
  width: 40vw;
  margin: 2vw;
}
.MyBrand__Vitality__Colour:after{
  content: '';
  position: absolute;
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  background: red;
  z-index: -1;
}
.MyBrand__GoingLiveHistory {
  position: relative;
  width: 90vw;
  color: #fff;
  margin: 0 auto;
  transition: all 0.5s;
}
.MyBrand__GoingLiveHistory__HeaderContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.MyBrand__GoingLiveHistory__Title {
  font-size: 7vw;
  margin: 2vw 0;
  line-height: 0;
}

.MyBrand__GoingLiveHistory__TitleLine {
}

#MonthSelectListStS {
  position: absolute;
  top: 0vw;
  right: 0vw;
  -webkit-appearance: none;
  appearance: none;
  background-color: black;
  text-align: center;
  color: white;
  padding: 0 3vw;
  font-size: 3.35vw;
  line-height: 6vw;
  border: 0.15vw solid white;
  border-radius: 10vw;
}
.MyBrand__GoingLiveHistory__Table {
  width: 90vw;
  font-size: 3.25vw;
  line-height: 5vw;
}
.MyBrand__GoingLiveHistory__Table td{
  padding: 2vw 1vw;
  text-align: left;
}
.MyBrand__GoingLiveHistory__Table th{
  text-align: left;
  vertical-align: top;
  width: 20vw;
}
.MyBrand__GoingLiveHistory__Table tr{
  vertical-align: top;
}

#NoTrnsStS {
  font-size: 4.5vw;
  line-height: 6vw;
  margin: 8vw 0 0 5vw;
  text-align: left;
  font-weight: normal;
  color: #ffbf12;
}

#ColumnsW {
  position: absolute;
  top: 16vw;
  left: 0%;
  width: 100%;
  text-transform: none;
  color: #9c9c9c;
}

#ColumnsW span:nth-child(1) {
  position: absolute;
  top: 0vw;
  left: 0%;
  font-size: 3.5vw;
}

#ColumnsW span:nth-child(2) {
  position: absolute;
  top: 0vw;
  left: 12.5%;
  font-size: 3.5vw;
}

#ColumnsW span:nth-child(3) {
  position: absolute;
  top: 0vw;
  left: 39.5%;
  font-size: 3.5vw;
}

#ColumnsW span:nth-child(4) {
  position: absolute;
  top: 0vw;
  right: 2%;
  font-size: 3.5vw;
}

#ColumnsW span:nth-child(5) {
  position: absolute;
  top: 0vw;
  left: 80%;
  font-size: 3.5vw;
}

#TransListLine1 {
  position: absolute;
  top: 24.5vw;
  left: 0%;
}

#TransListLine2 {
  position: absolute;
  top: 111.5vw;
  left: 0%;
  box-shadow: 0 0 14vw 11vw black;
}

#TransactionsListW {
  position: relative;
  top: 16vw;
  left: 0;
  width: 100%;
  /* height: 100vw; */
  margin: 0 0 20vw 0;
}

#TransactionsListW>ul {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  font-size: 3.8vw;
  padding: 0;
  height: 77vw;
  overflow: scroll;
}

#TransactionsListW>ul::-webkit-scrollbar {
  width: 0 !important;
}

#TransactionsListW li {
  list-style-type: none;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 4vw 0;
  white-space: normal !important;
  text-align: left !important;
}

#TransactionsListW span:nth-child(1) {
  position: relative;
  left: 0vw;
  top: -0.9vw;
  font-size: 3.8vw;
  line-height: 5vw;
  padding: 2vw 0;
}

#TransactionsListW span:nth-child(2) {
  position: absolute;
  left: 10.2vw;
  width: 3vw;
  line-height: 5vw;
  margin: auto;
  padding: 2vw 0;
}

#TransactionsListW span:nth-child(3) {
  position: absolute;
  width: 43%;
  text-align: left;
  left: 35vw;
  line-height: 5vw;
  padding: 2vw 0;
}

#TransactionsListW span:nth-child(4) {
  position: absolute;
  right: 0;
  width: 25%;
  text-align: right;
  font-size: 3.8vw;
  line-height: 5vw;
  padding: 2vw 0;
}

/* #TransactionsListW span:nth-child(5){
    position: absolute;
    left: 6vw;
    width: 25%;
    text-align: right;
} */
#TransactionsListW i {
  font-weight: normal;
  font-style: normal;
  font-size: 2.5vw;
}

.PositiveNum {
  color: #ffbf12;
}

.NegativeNum {
  color: #e66100;
}

.PositiveGreenNum {
  color: #34c100;
}