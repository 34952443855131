#ConvertPPTToPSDiv{
    position: absolute;
    left: 0;
    top:15vw;
    width: 100%;
}
#BackPageCPPTTPS {
    position: absolute;
    top: 2vw;
    left: 0%;
    width: 13vw;
    z-index: 35;
}
.StockCardPosCPPTTPS {
    position: absolute;
    top: 57vw;
    left: 5%;
    width: 90%;
}
#ForTxtCPPTTPS {
    position: absolute;
    top: 42vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 6vw;
    line-height: 8vw;
    margin: 0;
}

#ArrowLeftCPPTTPS {
    position: absolute;
    top: 41vw;
    right: 13vw;
    width: 13%;
}
#ArrowRightCPPTTPS {
    position: absolute;
    top: 41vw;
    left: 13vw;
    width: 13%;
}

#SMCardCPPTTPS {
    position: absolute;
    top: 8.5vw;
    left: 20.5vw;
    width: 59vw;
    height: 27vw;
    background-color: #252525fa;
    border-radius: 10px;
    /* z-index: 666; */
}
#InstaLogoCPPTTPS {
    position: absolute;
    top: 1vw;
    left: 0.7vw;
    width: 45%;
}
#InstaTxtCPPTTPS {
    position: absolute;
    top: 4vw;
    left: 49%;
    text-align: left;
    font-size: 4.5vw;
    line-height: 6vw;
    margin: 0;
    color: #b8b8b8;
}

#LineCPPTTPS {
    position: absolute;
    top: 108vw;
    left: 5vw;
}

#TradingInTxtCPPTTPS {
    position: absolute;
    top: 114.5vw;
    left: 5vw;
    font-size: 5.5vw;
    line-height: 7vw;
    color: #b8b8b8;
    margin: 0;
}

#CancelBtnCPPTTPS {
    position: absolute;
    top: 144vw;
    left: 5vw;
    border-radius: 25px;
    border: 0.4vw solid white;
    font-size: 4.5vw;
    line-height: 10.1vw;
    padding: 0 8.5vw;
    margin: 0;
}

#ConfirmBtnCPPTTPS {
    position: absolute;
    top: 144vw;
    right: 5vw;
    border-radius: 25px;
    background-color: white;
    font-size: 4.5vw;
    line-height: 10.5vw;
    color: #ffbf12;
    padding: 0 8.5vw;
    margin: 0;
}

#ErrorMessageCPPTTPS {
    position: fixed;
    bottom: 0;
    background-color: #363636;
    text-align: center;
    font-size: 6vw;
    line-height: 9vw;
    color: #ffbf12;
    padding: 4vw 0;
    margin: 0;
}